export default [
  {
    title: 'Verlonen',
    icon: 'BriefcaseIcon',
    action: 'read',
    resource: 'payslips',
    children: [
      {
        title: 'Loonstroken & Uitbetalingen',
        route: 'app-payslips',
        icon: 'FileTextIcon',
        action: 'read',
        resource: 'payslips',
      },
      {
        title: 'Verloonopties',
        route: 'app-payout-options',
        icon: 'SlidersIcon',
        action: 'read',
        resource: 'payslips-options',
      },
    ],
  },
]
