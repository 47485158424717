<template>
  <b-nav-item-dropdown
    right
    toggle-class="d-flex align-items-center dropdown-user-link"
    class="dropdown-user"
  >
    <template #button-content>
      <div class="d-sm-flex d-none user-nav">
        <p class="user-name font-weight-bolder mb-0">
          {{ userData.fullName || userData.username }}
        </p>
        <span
          v-if="userData.type === 2"
          class="user-status"
        >{{ userData.companyName }}</span>
      </div>
      <b-avatar
        size="40"
        :src="userData.avatar"
        variant="light-primary"
        badge
        class="badge-minimal"
        badge-variant="success"
      >
        <feather-icon
          v-if="!userData.fullName"
          icon="UserIcon"
          size="22"
        />
      </b-avatar>
    </template>

    <b-dropdown-item
      :to="{ name: 'app-settings-account'}"
      link-class="d-flex align-items-center"
    >
      <feather-icon
        size="16"
        icon="UserIcon"
        class="mr-50"
      />
      <span>Profiel</span>
    </b-dropdown-item>

    <b-dropdown-divider />

    <!--    <b-dropdown-item-->
    <!--      :to="{ name: 'app-payout-options' }"-->
    <!--      link-class="d-flex align-items-center"-->
    <!--    >-->
    <!--      <feather-icon-->
    <!--        size="16"-->
    <!--        icon="SettingsIcon"-->
    <!--        class="mr-50"-->
    <!--      />-->
    <!--      <span>Verloonopties</span>-->
    <!--    </b-dropdown-item>-->
    <b-dropdown-item
      link-class="d-flex align-items-center"
      @click="logout"
    >
      <feather-icon
        size="16"
        icon="LogOutIcon"
        class="mr-50"
      />
      <span>Logout</span>
    </b-dropdown-item></b-nav-item-dropdown>
</template>

<script>
/* eslint-disable no-param-reassign */
import {
  BNavItemDropdown, BDropdownItem, BDropdownDivider, BAvatar,
} from 'bootstrap-vue'
import { initialAbility } from '@/libs/acl/config'
import useJwt from '@/auth/jwt/useJwt'
import { avatarText } from '@core/utils/filter'

export default {
  components: {
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
      avatarText,
      trengoMounted: false,
    }
  },
  mounted() {
    if (!this.trengoMounted) {
      window.Trengo = window.Trengo || {}
      window.Trengo.key = 'm2XlvlBUzqztBdqUrOby';
      // eslint-disable-next-line no-unused-vars,func-names
      (function (d, script, t) {
        script = d.createElement('script')
        script.type = 'text/javascript'
        script.async = true
        script.src = 'https://cdn.widget.trengo.eu/embed.js'
        d.getElementsByTagName('head')[0].appendChild(script)
      }(document))
      if (this.userData && this.userData.email !== null) {
        window.Trengo.contact_data = {
          name: this.userData.fullName,
          email: this.userData.email,
          custom_fields: [{
            field_id: 152516,
            value: this.userData.id,
          }],
        }
      }

      this.trengoMounted = true
    }
  },
  methods: {
    logout() {
      document.getElementById('trengo-web-widget').setAttribute('style', 'display:none')
      // Remove userData from localStorage
      // ? You just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
      localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
      localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)

      // Remove userData from localStorage
      localStorage.removeItem('userData')

      // Reset ability
      this.$ability.update(initialAbility)

      // Redirect to login page
      this.$router.push({ name: 'auth-login' })
    },
  },
}
</script>
